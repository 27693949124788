// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---readme-md": () => import("./../../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---sgc-md": () => import("./../../sgc.md" /* webpackChunkName: "component---sgc-md" */),
  "component---src-admin-mdx": () => import("./../../src/admin.mdx" /* webpackChunkName: "component---src-admin-mdx" */),
  "component---src-components-alert-mdx": () => import("./../../src/components/Alert.mdx" /* webpackChunkName: "component---src-components-alert-mdx" */),
  "component---src-components-button-mdx": () => import("./../../src/components/Button.mdx" /* webpackChunkName: "component---src-components-button-mdx" */),
  "component---src-frontend-mdx": () => import("./../../src/frontend.mdx" /* webpackChunkName: "component---src-frontend-mdx" */),
  "component---src-index-mdx": () => import("./../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-rangescheduler-admin-mdx": () => import("./../../src/rangescheduler/admin.mdx" /* webpackChunkName: "component---src-rangescheduler-admin-mdx" */),
  "component---src-rangescheduler-frontend-mdx": () => import("./../../src/rangescheduler/frontend.mdx" /* webpackChunkName: "component---src-rangescheduler-frontend-mdx" */)
}

