module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"C:\\wamp\\www\\docz\\sgc-documentation\\node_modules\\gatsby-theme-docz\\src\\base\\Layout.js"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{},"src":"./","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":[],"mdPlugins":[],"hastPlugins":[],"ignore":[],"typescript":true,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"C:\\wamp\\www\\docz\\sgc-documentation\\.docz","base":"/","source":"./","gatsby-root":null,"files":"**/*.{md,markdown,mdx}","public":"/public","dest":".docz/dist","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"Sgc Range Scheduler","description":"My awesome app using docz","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"C:\\wamp\\www\\docz\\sgc-documentation","templates":"C:\\wamp\\www\\docz\\sgc-documentation\\node_modules\\docz-core\\dist\\templates","docz":"C:\\wamp\\www\\docz\\sgc-documentation\\.docz","cache":"C:\\wamp\\www\\docz\\sgc-documentation\\.docz\\.cache","app":"C:\\wamp\\www\\docz\\sgc-documentation\\.docz\\app","appPackageJson":"C:\\wamp\\www\\docz\\sgc-documentation\\package.json","appTsConfig":"C:\\wamp\\www\\docz\\sgc-documentation\\tsconfig.json","gatsbyConfig":"C:\\wamp\\www\\docz\\sgc-documentation\\gatsby-config.js","gatsbyBrowser":"C:\\wamp\\www\\docz\\sgc-documentation\\gatsby-browser.js","gatsbyNode":"C:\\wamp\\www\\docz\\sgc-documentation\\gatsby-node.js","gatsbySSR":"C:\\wamp\\www\\docz\\sgc-documentation\\gatsby-ssr.js","importsJs":"C:\\wamp\\www\\docz\\sgc-documentation\\.docz\\app\\imports.js","rootJs":"C:\\wamp\\www\\docz\\sgc-documentation\\.docz\\app\\root.jsx","indexJs":"C:\\wamp\\www\\docz\\sgc-documentation\\.docz\\app\\index.jsx","indexHtml":"C:\\wamp\\www\\docz\\sgc-documentation\\.docz\\app\\index.html","db":"C:\\wamp\\www\\docz\\sgc-documentation\\.docz\\app\\db.json"}},
    }]
